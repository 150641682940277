@font-face {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/GothamProNormal/GothamPro.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/GothamProNormal/GothamPro.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/GothamProNormal/GothamPro.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/GothamProBold/GothamPro-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("../assets/fonts/GothamProBold/GothamPro-Bold.woff") format("woff"),
    /* Modern Browsers */
      url("../assets/fonts/GothamProBold/GothamPro-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}
